import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  PlusCircle,
  Mail,
  RefreshCw,
  Trash2,
  Copy,
} from 'lucide-react';
import ReactModal from 'react-modal';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

// Constants
const GLOBAL_ROUND = '30px';
const REFRESH_INTERVAL = 10000;

// API Endpoints
const API_ENDPOINTS = {
  TASKS: '/api/tasks',
  DOMAINS: '/api/domains',
  EMAILS: '/api/emails',
  GENERATE_EMAIL: '/api/generateEmail',
  DELETE_EMAILS: '/api/deleteEmails',
  ADD_DOMAIN: '/api/addDomain',
  ADD_USER: '/api/addUser',
  VERIFY_TOKEN: '/api/verifyToken',
};

// Set ReactModal App Element for accessibility
ReactModal.setAppElement('#root');

// Utility Functions
const generateSignature = (payload) => {
  const secret = process.env.REACT_APP_API_SECRET;
  if (!secret) throw new Error('API secret is not configured');
  return CryptoJS.HmacSHA256(JSON.stringify(payload), secret).toString();
};

const sendRequest = async (url, method, payload) => {
  const signature = generateSignature(payload);
  const authToken = Cookies.get('authToken');
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Signature': signature,
      'X-Auth-Token': authToken || '',
    },
    body: method !== 'GET' ? JSON.stringify(payload) : undefined,
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${errorText}`
    );
  }

  return response.json();
};

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error */) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    // You can log the error to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
          <div className="text-center">
            <h1 className="text-3xl mb-4">Something went wrong.</h1>
            <p>Please try refreshing the page or contact support.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom Hook for Authentication
const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(
    !!Cookies.get('authToken')
  );
  const [authToken, setAuthToken] = useState(
    Cookies.get('authToken') || ''
  );
  const [isAdmin, setIsAdmin] = useState(
    Cookies.get('authToken') === process.env.REACT_APP_ADMIN_TOKEN
  );
  const [authError, setAuthError] = useState('');

  const handleAuthorize = useCallback(async (token) => {
    try {
      const response = await fetch(API_ENDPOINTS.VERIFY_TOKEN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === 'Token is valid') {
          setAuthToken(token);
          setIsAuthorized(true);
          Cookies.set('authToken', token, { expires: 7 });
          setIsAdmin(token === process.env.REACT_APP_ADMIN_TOKEN);
          setAuthError('');
        } else {
          setAuthError('Invalid authentication');
        }
      } else {
        setAuthError('Invalid authentication');
      }
    } catch (error) {
      console.error('Authorization error:', error);
      setAuthError('An error occurred during authorization');
    }
  }, []);

  const handleLogout = useCallback(() => {
    setAuthToken('');
    setIsAuthorized(false);
    setIsAdmin(false);
    Cookies.remove('authToken');
  }, []);

  return {
    isAuthorized,
    authToken,
    isAdmin,
    authError,
    handleAuthorize,
    handleLogout,
  };
};

// Custom Hook for Fetching Data
const useFetch = (url, method = 'GET', payload = {}, dependencies = []) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await sendRequest(url, method, payload);
      setData(result);
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [url, method, payload]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // Dependencies control when to refetch

  return { data, loading, error, refetch: fetchData };
};

// Custom Hook for Fetching Tasks
const useFetchTasks = () => {
  const { data, loading, error, refetch } = useFetch(API_ENDPOINTS.TASKS, 'GET', {}, []);
  
  // Sort tasks by timestamp descending
  const sortedTasks = data ? [...data].sort((a, b) => b.timestamp - a.timestamp) : [];
  
  return { tasks: sortedTasks, loading, error, refetch };
};

// Reducer for Managing Complex State
const initialState = {
  selectedEmail: null,
  inbox: [],
  tasks: [],
  domains: [],
  searchTerm: '',
  selectedTask: JSON.parse(localStorage.getItem('selectedTask')) || null,
  newDomain: '',
  password: '',
  error: '',
  success: '',
  showAddDomainModal: false,
  copySuccess: '',
  showDomainsModal: false,
  backendError: false,
  showAddUserModal: false,
  newUsername: '',
  newUserToken: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_EMAIL':
      return { ...state, selectedEmail: action.payload };
    case 'SET_INBOX':
      return { ...state, inbox: action.payload };
    case 'SET_TASKS':
      return { ...state, tasks: action.payload };
    case 'SET_DOMAINS':
      return { ...state, domains: action.payload };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
    case 'SET_SELECTED_TASK':
      localStorage.setItem('selectedTask', JSON.stringify(action.payload));
      return { ...state, selectedTask: action.payload };
    case 'SET_NEW_DOMAIN':
      return { ...state, newDomain: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SUCCESS':
      return { ...state, success: action.payload };
    case 'SET_SHOW_ADD_DOMAIN_MODAL':
      return { ...state, showAddDomainModal: action.payload };
    case 'SET_COPY_SUCCESS':
      return { ...state, copySuccess: action.payload };
    case 'SET_SHOW_DOMAINS_MODAL':
      return { ...state, showDomainsModal: action.payload };
    case 'SET_BACKEND_ERROR':
      return { ...state, backendError: action.payload };
    case 'SET_SHOW_ADD_USER_MODAL':
      return { ...state, showAddUserModal: action.payload };
    case 'SET_NEW_USERNAME':
      return { ...state, newUsername: action.payload };
    case 'SET_NEW_USER_TOKEN':
      return { ...state, newUserToken: action.payload };
    case 'RESET_SUCCESS':
      return { ...state, success: '' };
    case 'RESET_ERROR':
      return { ...state, error: '' };
    default:
      return state;
  }
};

// Email Content Component
const EmailContent = memo(({ email }) => {
  if (email.content_type === 'text/html') {
    return (
      <div
        className="email-content"
        dangerouslySetInnerHTML={{ __html: email.content }}
      />
    );
  }
  // For plain text, preserve whitespace and line breaks
  return (
    <pre className="email-content whitespace-pre-wrap font-sans">
      {email.content}
    </pre>
  );
});

EmailContent.propTypes = {
  email: PropTypes.shape({
    content_type: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

// Authorization Modal Component
const AuthorizationModal = memo(({ onAuthorize, errorMessage }) => {
  const [token, setToken] = useState('');

  const handleAuthorize = () => {
    onAuthorize(token);
  };

  return (
    <ReactModal
      isOpen={true}
      className="modal-content flex items-center justify-center rounded-lg bg-gray-800 p-6 transition-all duration-300 focus:outline-none"
      overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      shouldCloseOnOverlayClick={false}
      contentLabel="Authorization Modal"
    >
      <div className="p-6 bg-gray-800 rounded-lg w-80">
        <h2 className="text-xl mb-4 text-white text-center">Login</h2>
        <input
          type="password"
          placeholder="Enter your token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          className="w-full mb-2 p-2 rounded bg-gray-900 text-gray-100"
        />
        {errorMessage && (
          <div className="text-red-500 mb-2">{errorMessage}</div>
        )}
        <button
          onClick={handleAuthorize}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
          disabled={!token.trim()}
        >
          Authorize
        </button>
      </div>
    </ReactModal>
  );
});

AuthorizationModal.propTypes = {
  onAuthorize: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

AuthorizationModal.defaultProps = {
  errorMessage: '',
};

// Dashboard Component
const Dashboard = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selectedEmail,
    inbox,
    tasks,
    domains,
    searchTerm,
    selectedTask,
    newDomain,
    password,
    error,
    success,
    showAddDomainModal,
    copySuccess,
    showDomainsModal,
    backendError,
    showAddUserModal,
    newUsername,
    newUserToken,
  } = state;

  const {
    isAuthorized,
    isAdmin,
    authError,
    handleAuthorize,
    handleLogout,
  } = useAuth();

  // Ensure useMemo is called at the top level of the component
  const filteredInbox = useMemo(() => {
    const term = searchTerm.toLowerCase();
    return inbox
      .filter(
        (email) =>
          email.subject.toLowerCase().includes(term) ||
          email.content.toLowerCase().includes(term)
      )
      .sort((a, b) => b.timestamp - a.timestamp); // Sort emails by timestamp descending
  }, [inbox, searchTerm]);

  // Initialize useFetchTasks to obtain refetch
  const { tasks: fetchedTasks, loading, error: fetchTasksError, refetch } = useFetchTasks();

  // Fetch Tasks and Domains on Mount
  const fetchInitialData = useCallback(async () => {
    try {
      const [tasksData, domainsData] = await Promise.all([
        sendRequest(API_ENDPOINTS.TASKS, 'GET', {}),
        sendRequest(API_ENDPOINTS.DOMAINS, 'GET', {}),
      ]);
      dispatch({ type: 'SET_TASKS', payload: tasksData || [] });
      dispatch({ type: 'SET_DOMAINS', payload: domainsData.domains || [] });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to fetch initial data: ${err.message}`,
      });
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  // Fetch Inbox when selectedTask changes
  const fetchInbox = useCallback(async (task_id) => {
    if (!task_id) return;
    try {
      dispatch({ type: 'RESET_ERROR' });
      const data = await sendRequest(
        `${API_ENDPOINTS.EMAILS}/${task_id}`,
        'GET',
        {}
      );
      dispatch({ type: 'SET_INBOX', payload: data.emails || data || [] });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to fetch inbox: ${err.message}`,
      });
    }
  }, []);

  // Polling for Inbox
  useEffect(() => {
    let intervalId = null;
    if (selectedTask) {
      fetchInbox(selectedTask.task_id);
      intervalId = setInterval(() => {
        fetchInbox(selectedTask.task_id);
      }, REFRESH_INTERVAL);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [selectedTask, fetchInbox]);

  // Handlers
  const handleCreateNewEmail = useCallback(async () => {
    try {
      dispatch({ type: 'RESET_ERROR' });
      const data = await sendRequest(
        API_ENDPOINTS.GENERATE_EMAIL,
        'POST',
        {}
      );
      if (data.task_id && data.email) {
        dispatch({
          type: 'SET_TASKS',
          payload: [{ task_id: data.task_id, email_alias: data.email }, ...tasks],
        });
        dispatch({
          type: 'SET_SUCCESS',
          payload: 'New email created successfully',
        });
        setTimeout(() => dispatch({ type: 'RESET_SUCCESS' }), 2000);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to create email: ${err.message}`,
      });
    }
  }, [tasks]);

  const handleDeleteEmail = useCallback(async () => {
    if (!selectedTask?.task_id || !selectedEmail?.id) return;
    try {
      dispatch({ type: 'RESET_ERROR' });
      await sendRequest(
        `${API_ENDPOINTS.DELETE_EMAILS}?task_id=${selectedTask.task_id}`,
        'DELETE',
        { email_id: selectedEmail.id }
      );
      dispatch({
        type: 'SET_INBOX',
        payload: inbox.filter((email) => email.id !== selectedEmail.id),
      });
      dispatch({ type: 'SET_SELECTED_EMAIL', payload: null });
      dispatch({
        type: 'SET_SUCCESS',
        payload: 'Email deleted successfully',
      });
      setTimeout(() => dispatch({ type: 'RESET_SUCCESS' }), 2000);
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to delete email: ${err.message}`,
      });
    }
  }, [selectedTask, selectedEmail, inbox]);

  const handleAddDomain = useCallback(async () => {
    if (!newDomain.trim() || !password.trim()) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please enter both domain and password.',
      });
      return;
    }
    try {
      dispatch({ type: 'RESET_ERROR' });
      const response = await sendRequest(API_ENDPOINTS.ADD_DOMAIN, 'POST', {
        domain: newDomain,
        password,
      });
      if (response.status === 201) {
        dispatch({
          type: 'SET_DOMAINS',
          payload: [...domains, newDomain],
        });
        dispatch({
          type: 'SET_SUCCESS',
          payload: 'Domain added successfully!',
        });
        setTimeout(() => {
          dispatch({ type: 'RESET_SUCCESS' });
          dispatch({ type: 'SET_NEW_DOMAIN', payload: '' });
          dispatch({ type: 'SET_PASSWORD', payload: '' });
          dispatch({ type: 'SET_SHOW_ADD_DOMAIN_MODAL', payload: false });
        }, 2000);
      } else {
        throw new Error('Failed to add domain');
      }
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to add domain: ${err.message}`,
      });
    }
  }, [newDomain, password, domains]);

  const handleCopyEmail = useCallback(async (email) => {
    try {
      await navigator.clipboard.writeText(email);
      dispatch({
        type: 'SET_COPY_SUCCESS',
        payload: 'Email copied to clipboard!',
      });
      setTimeout(() => dispatch({ type: 'SET_COPY_SUCCESS', payload: '' }), 2000);
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Failed to copy email to clipboard',
      });
    }
  }, []);

  const handleRefreshInbox = useCallback(() => {
    if (selectedTask) {
      fetchInbox(selectedTask.task_id);
    }
  }, [selectedTask, fetchInbox]);

  const handleSelectTask = useCallback(
    (task) => {
      dispatch({ type: 'SET_SELECTED_TASK', payload: task });
      dispatch({ type: 'SET_SELECTED_EMAIL', payload: null });
    },
    []
  );

  const handleSearchChange = useCallback((e) => {
    dispatch({ type: 'SET_SEARCH_TERM', payload: e.target.value });
  }, []);

  const openAddDomainModal = useCallback(() => {
    dispatch({ type: 'SET_SHOW_ADD_DOMAIN_MODAL', payload: true });
  }, []);

  const closeAddDomainModal = useCallback(() => {
    dispatch({ type: 'SET_SHOW_ADD_DOMAIN_MODAL', payload: false });
    dispatch({ type: 'RESET_ERROR' });
    dispatch({ type: 'RESET_SUCCESS' });
    dispatch({ type: 'SET_NEW_DOMAIN', payload: '' });
    dispatch({ type: 'SET_PASSWORD', payload: '' });
  }, []);

  const openDomainsModal = useCallback(() => {
    dispatch({ type: 'SET_SHOW_DOMAINS_MODAL', payload: true });
  }, []);

  const closeDomainsModal = useCallback(() => {
    dispatch({ type: 'SET_SHOW_DOMAINS_MODAL', payload: false });
  }, []);

  const handleDeleteDomain = useCallback(
    async (domain) => {
      try {
        dispatch({ type: 'RESET_ERROR' });
        await sendRequest(`${API_ENDPOINTS.ADD_DOMAIN}/${domain}`, 'DELETE', {});
        dispatch({
          type: 'SET_DOMAINS',
          payload: domains.filter((d) => d !== domain),
        });
        dispatch({
          type: 'SET_SUCCESS',
          payload: 'Domain deleted successfully!',
        });
        setTimeout(() => dispatch({ type: 'RESET_SUCCESS' }), 2000);
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          payload: `Failed to delete domain: ${err.message}`,
        });
      }
    },
    [domains]
  );

  const handleAddUser = useCallback(async () => {
    if (!newUsername.trim() || !newUserToken.trim()) {
      dispatch({
        type: 'SET_ERROR',
        payload: 'Please enter both username and token.',
      });
      return;
    }
    try {
      dispatch({ type: 'RESET_ERROR' });
      await sendRequest(API_ENDPOINTS.ADD_USER, 'POST', {
        username: newUsername,
        token: newUserToken,
      });
      dispatch({
        type: 'SET_SUCCESS',
        payload: 'User added successfully!',
      });
      setTimeout(() => {
        dispatch({ type: 'RESET_SUCCESS' });
        dispatch({ type: 'SET_NEW_USERNAME', payload: '' });
        dispatch({ type: 'SET_NEW_USER_TOKEN', payload: '' });
        dispatch({ type: 'SET_SHOW_ADD_USER_MODAL', payload: false });
      }, 2000);
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: `Failed to add user: ${err.message}`,
      });
    }
  }, [newUsername, newUserToken]);

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  // Conditional Rendering based on Authorization
  if (!isAuthorized) {
    return (
      <AuthorizationModal
        onAuthorize={handleAuthorize}
        errorMessage={authError}
      />
    );
  }

  return (
    <ErrorBoundary>
      <div className="flex h-screen bg-gray-900 text-gray-100">
        {/* Left Sidebar */}
        <aside className="w-64 bg-gray-800 p-4 flex flex-col">
          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="mb-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
          >
            Logout
          </button>
          {/* Create New Email Button */}
          <button
            onClick={handleCreateNewEmail}
            className={`mb-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-xl transition-all duration-300 flex items-center`}
          >
            <PlusCircle className="mr-2 h-4 w-4" />
            Create New Email
          </button>
          {/* Tasks List */}
          <div className="flex-grow overflow-auto">
            {tasks.map((task) => (
              <div
                key={task.task_id}
                className={`mb-2 p-2 rounded hover:bg-gray-700 cursor-pointer transition-colors ${
                  selectedTask && selectedTask.task_id === task.task_id
                    ? 'bg-gray-700'
                    : ''
                }`}
                onClick={() => handleSelectTask(task)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div>{task.email_alias.split('@')[0]}</div>
                    <div className="text-sm text-gray-400">
                      {task.email_alias.split('@')[1]}
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyEmail(task.email_alias);
                    }}
                    className="text-gray-400 hover:text-gray-200"
                    aria-label="Copy Email"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Admin Actions */}
          {isAdmin && (
            <div className="flex flex-col gap-2 mt-4">
              <button
                onClick={openAddDomainModal}
                className={`bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-xl transition-all duration-300 flex items-center justify-center`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
                Add New Domain
              </button>
              <button
                onClick={openDomainsModal}
                className={`bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-xl transition-all duration-300 flex items-center justify-center`}
              >
                <Mail className="h-5 w-5 mr-2" />
                View Domains
              </button>
              <button
                onClick={() => dispatch({ type: 'SET_SHOW_ADD_USER_MODAL', payload: true })}
                className={`bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-xl transition-all duration-300 flex items-center justify-center`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
                Add New User
              </button>
            </div>
          )}
        </aside>

        {/* Main Panel */}
        <main className="flex-1 flex flex-col">
          {/* Inbox Header */}
          <header className="bg-gray-800 p-4 flex items-center justify-between">
            <h2 className="text-xl">Inbox</h2>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Search emails..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-64 bg-gray-700 text-gray-100 placeholder-gray-400 px-4 py-2 rounded"
              />
              <button
                onClick={handleRefreshInbox}
                className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-xl transition-all duration-300"
                aria-label="Refresh Inbox"
              >
                <RefreshCw className="h-4 w-4" />
              </button>
              {selectedEmail && (
                <button
                  onClick={handleDeleteEmail}
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-xl transition-all duration-300 flex items-center"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  Delete
                </button>
              )}
            </div>
          </header>

          {/* Inbox List */}
          <section className="flex-grow overflow-auto">
            {filteredInbox.length > 0 ? (
              filteredInbox.map((email, index) => (
                <div
                  key={email.id || index}
                  className="p-4 border-b border-gray-700 hover:bg-gray-800 cursor-pointer transition-colors"
                  onClick={() => dispatch({ type: 'SET_SELECTED_EMAIL', payload: email })}
                >
                  <div className="flex items-center justify-between">
                    <h3>{email.subject}</h3>
                    <span className="text-sm text-gray-400">
                      {new Date(email.timestamp * 1000).toLocaleString()}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">
                No emails found.
              </div>
            )}
          </section>

          {/* Email Viewer */}
          {selectedEmail && (
            <section className="bg-gray-800 p-4 border-t border-gray-700">
              <h3 className="text-xl mb-2">{selectedEmail.subject}</h3>
              <p className="text-sm text-gray-400 mb-4">
                {new Date(selectedEmail.timestamp * 1000).toLocaleString()}
              </p>
              <EmailContent email={selectedEmail} />
            </section>
          )}

          {/* Add Domain Modal */}
          <ReactModal
            isOpen={showAddDomainModal}
            onRequestClose={closeAddDomainModal}
            className="modal-content flex items-center justify-center rounded-lg bg-gray-800 p-6 transition-all duration-300 focus:outline-none"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            contentLabel="Add Domain Modal"
          >
            <div className="p-6 bg-gray-800 rounded-lg w-80">
              <h2 className="text-xl mb-4 text-white text-center">Add New Domain</h2>
              <input
                type="text"
                placeholder="Domain"
                value={newDomain}
                onChange={(e) => dispatch({ type: 'SET_NEW_DOMAIN', payload: e.target.value })}
                className="w-full mb-2 p-2 rounded bg-gray-900 text-gray-100"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => dispatch({ type: 'SET_PASSWORD', payload: e.target.value })}
                className="w-full mb-2 p-2 rounded bg-gray-900 text-gray-100"
              />
              {error && <div className="text-red-500 mb-2">{error}</div>}
              {success && <div className="text-green-500 mb-2">{success}</div>}
              <button
                onClick={handleAddDomain}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
                disabled={!newDomain.trim() || !password.trim()}
              >
                Add Domain
              </button>
              <button
                onClick={closeAddDomainModal}
                className="mt-2 w-full bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </ReactModal>

          {/* Domains List Modal */}
          <ReactModal
            isOpen={showDomainsModal}
            onRequestClose={closeDomainsModal}
            className="modal-content flex items-center justify-center rounded-lg bg-gray-800 p-6 transition-all duration-300 focus:outline-none"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            contentLabel="Domains List Modal"
          >
            <div className="p-6 bg-gray-800 rounded-lg w-96 max-h-full overflow-auto">
              <h2 className="text-xl mb-4 text-gray-100">Available Domains</h2>
              <div className="space-y-2">
                {domains.length > 0 ? (
                  domains.map((domain, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center bg-gray-700 p-3 rounded-lg"
                    >
                      <span className="text-white">{domain}</span>
                      <button
                        onClick={() => handleDeleteDomain(domain)}
                        className="text-red-500 hover:text-red-300"
                        aria-label={`Delete domain ${domain}`}
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500">No domains available.</div>
                )}
              </div>
              <button
                onClick={closeDomainsModal}
                className="mt-4 w-full bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
              >
                Close
              </button>
            </div>
          </ReactModal>

          {/* Add User Modal */}
          <ReactModal
            isOpen={showAddUserModal}
            onRequestClose={() => dispatch({ type: 'SET_SHOW_ADD_USER_MODAL', payload: false })}
            className="modal-content flex items-center justify-center rounded-lg bg-gray-800 p-6 transition-all duration-300 focus:outline-none"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            contentLabel="Add User Modal"
          >
            <div className="p-6 bg-gray-800 rounded-lg w-80">
              <h2 className="text-xl mb-4 text-white text-center">Add New User</h2>
              <input
                type="text"
                placeholder="Username"
                value={newUsername}
                onChange={(e) => dispatch({ type: 'SET_NEW_USERNAME', payload: e.target.value })}
                className="w-full mb-2 p-2 rounded bg-gray-900 text-gray-100"
              />
              <input
                type="password"
                placeholder="Token"
                value={newUserToken}
                onChange={(e) => dispatch({ type: 'SET_NEW_USER_TOKEN', payload: e.target.value })}
                className="w-full mb-2 p-2 rounded bg-gray-900 text-gray-100"
              />
              {error && <div className="text-red-500 mb-2">{error}</div>}
              {success && <div className="text-green-500 mb-2">{success}</div>}
              <button
                onClick={handleAddUser}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
                disabled={!newUsername.trim() || !newUserToken.trim()}
              >
                Add User
              </button>
              <button
                onClick={() => dispatch({ type: 'SET_SHOW_ADD_USER_MODAL', payload: false })}
                className="mt-2 w-full bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-xl transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </ReactModal>

          {/* Copy Success Notification */}
          {copySuccess && (
            <div className="fixed bottom-4 right-4 bg-green-600 text-white py-2 px-4 rounded-xl shadow-lg">
              {copySuccess}
            </div>
          )}

          {/* Backend Error Modal */}
          {backendError && (
            <ReactModal
              isOpen={backendError}
              onRequestClose={() => {}}
              className="modal-content rounded-lg bg-gray-800 p-6 flex flex-col items-center"
              overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
              contentLabel="Backend Error Modal"
            >
              <h2 className="text-xl mb-4">Service Unavailable</h2>
              <p className="mb-4">
                The backend service is currently inaccessible. Please ensure
                the server is running.
              </p>
              <button
                onClick={handleReload}
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-xl"
              >
                Reload
              </button>
            </ReactModal>
          )}

          {/* Global Error Message */}
          {error && !backendError && (
            <div className="fixed top-4 right-4 bg-red-600 text-white py-2 px-4 rounded-xl shadow-lg">
              {error}
              <button
                onClick={() => dispatch({ type: 'RESET_ERROR' })}
                className="ml-2 text-white font-bold"
                aria-label="Close Error"
              >
                &times;
              </button>
            </div>
          )}

          {/* Global Success Message */}
          {success && (
            <div className="fixed top-4 right-4 bg-green-600 text-white py-2 px-4 rounded-xl shadow-lg">
              {success}
              <button
                onClick={() => dispatch({ type: 'RESET_SUCCESS' })}
                className="ml-2 text-white font-bold"
                aria-label="Close Success"
              >
                &times;
              </button>
            </div>
          )}
        </main>
      </div>
    </ErrorBoundary>
  );
};

export default Dashboard;

